/**
 * @param {Date} data
 * @returns {string} Data no formato YYYY-MM-DD
 **/
function toMysql(data, hideSecs) {
    const dia = strZero(data.getUTCDate(), 2);
    const mes = strZero(data.getUTCMonth()+1, 2);
    const hora = strZero(data.getUTCHours(), 2);
    const minuto = strZero(data.getUTCMinutes(), 2);
    const segundo = strZero(data.getUTCSeconds(), 2);

    if (hideSecs === true) {
        return `${data.getUTCFullYear()}-${mes}-${dia} ${hora}:${minuto}`;
    }

    return `${data.getUTCFullYear()}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default toMysql