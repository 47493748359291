<template>
  <div class="clientes">
    <h1 class="titulo-card fs-4">Conexões / Erros</h1>
    <div class="form-group card-principal">
      <div class="d-flex justify-content-between">
        <div class="input-group mb-3">
          <button v-on:click="$router.go(-1)" class="btn btn-outline-primary">
            <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width class="me-1"/>Voltar
          </button>
        </div>
        <div class="input-group mb-3">
          <input
              v-on:keyup.enter="handlePesquisa()"
              type="text"
              class="form-control"
              placeholder="Pesquisar"
              aria-label="Pesquisar"
              aria-describedby="button-addon2">
          <button v-on:click="handlePesquisa()" class="btn btn-outline-secondary" type="button" id="button-addon2">
            <font-awesome-icon :icon="['fas', 'search']" fixed-width class="me-1"/>
          </button>
        </div>
      </div>

      <!-- table for mobile -->
      <div class="d-md-none">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th>Erros</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="session in connectionErrs" :key="session.id" @click="seeSession(session)" class="cursor-pointer text-danger">
                <td scope="row">
                  <span class="text-muted-2">{{godatetime().toMysql(session.createdAt)}}</span>
                  <br><strong>{{session.username}}</strong> - {{session.reason}}
                </td>
              </tr>
            </tbody>
        </table>
      </div>
      <!-- table for mobile -->

      <!-- table for desktop -->
      <div class="d-none d-md-flex">
        <table class="table table-sm-pc table-striped table-hover">
          <thead>
            <tr>
              <th width="140">Data</th>
              <th width="125">MAC</th>
              <th>Login</th>
              <th>Motivo</th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="session in connectionErrs" :key="session.id" @click="seeSession(session)" class="cursor-pointer text-danger">
                <td scope="row">{{godatetime().toMysql(session.createdAt)}}</td>
                <td>{{session.mac}}</td>
                <td>{{session.username}}</td>
                <td>{{session.reason}}</td>
              </tr>
            </tbody>
        </table>
      </div>
      <!-- table for desktop -->

      <Paginador v-model="paginador" @change-paginacao="onChangePaginacao"/>
    </div>

    <!-- modal-isp-connection-error-info -->
    <!-- Button trigger modal -->
    <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#modal-isp-connection-error-info" id="btn-modal-isp-connection-error-info-abre">
      Launch
    </button>

    <!-- Modal -->
    <div class="modal fade" id="modal-isp-connection-error-info" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-fullscreen-margin" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              <font-awesome-icon :icon="['fas', 'info-circle']" fixed-width/>
              Informações de erro da conexão
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div v-if="session" class="modal-body">
            <p class="mb-0"><strong>Usuário: </strong>{{session.username}}</p>
            <!-- <p class="mb-0"><strong>
              IPv4: </strong>{{session.framedIpAddressStr}}
              <a :href="linkToConnection(session)" target="_blank">
                <font-awesome-icon :icon="['fas', 'external-link-alt']" fixed-width/>
              </a>
            </p> -->
            <p class="mb-0"><strong>MAC: </strong>{{session.mac}}</p>
            <p class="mb-0"><strong>Motivo: </strong>{{session.reason}}</p>

          </div>
          <div class="modal-footer">
            <div class="d-flex justify-content-between w-100">
              <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="btn-modal-isp-connection-error-info-fecha">Fechar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal-isp-connection-error-info -->
  </div>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import api from '@/modules/api'
import dialogo from '@/modules/dialogo'
import loading from '@/modules/loading'
import clone from '@/modules/clone'
import model from '@/model'
import godatetime from '@/modules/godatetime'

export default {
  name: 'IspConnectionsErrors',
  components: {
    Paginador,
  },
  data() {
    return {
      /** @type {model.paginador} */
      paginador: clone(model.paginador),
      bngs: [],
      connectionErrs: [],
      session: undefined,
    }
  },
  methods: {
    godatetime() {
      return godatetime;
    },

    handlePesquisa() {
      loading(true);

      api.get(`/isp/sessions/errors?${this.paginador.requestParams}&orderBy[id]=desc`).then(res => {
        loading(false);
        this.connectionErrs = res.data.items;
        this.paginador.pagina = res.data.page;
        this.paginador.totalItens = res.data.totalItems;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    refreshBngs() {
      loading(true);
      api.get('/isp/bngs?active=1&paginate.cancel').then(res => {
        loading(false);

        this.bngs = res.data.items;
      }).catch(error => {
        loading(false);
        dialogo.erroHttp(error);
      });
    },

    onChangePaginacao() {
      this.handlePesquisa();
    },

    seeSession(session) {
      this.session = session;
      this.modalConnectionInfoOpen();
    },

    modalConnectionInfoOpen() {
      document.getElementById('btn-modal-isp-connection-error-info-abre').click();
    },

    modalConnectionInfoClose() {
      document.getElementById('btn-modal-isp-connection-error-info-fecha').click();
    },

    linkToConnection(session) {
      return `http://${session.framedIpAddressStr}/`
    },

    // TODO: REFACTOR
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 b';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
  },
  created() {
    this.handlePesquisa();
    this.refreshBngs();
  }
}
</script>
