/**
 * @param {Date} data
 * @returns {string} Data no formato YYYY-MM-DD
 **/
function toMysql(data, hideSecs) {
    const dia = strZero(data.getDate(), 2);
    const mes = strZero(data.getMonth()+1, 2);
    const hora = strZero(data.getHours(), 2);
    const minuto = strZero(data.getMinutes(), 2);
    const segundo = strZero(data.getSeconds(), 2);

    if (hideSecs === true) {
        return `${data.getFullYear()}-${mes}-${dia} ${hora}:${minuto}`;
    }

    return `${data.getFullYear()}-${mes}-${dia} ${hora}:${minuto}:${segundo}`;
}

function strZero(numero, zeros) {
    return Array(zeros-String(numero).length+1).join('0') + numero;
}

export default toMysql