/**
 * @param {string} input
 */
 function toDateTime(input) {
    if (typeof input !== 'string') {
        return '';
    }

    var out = new Date(input);

    return out;
}

export default toDateTime